<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-13, .cls-2, .cls-3, .cls-4, .cls-5, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#inox-gradient);
      }

      .cls-3 {
        fill: url(#inox-gradient-0);
      }

      .cls-4 {
        fill: url(#inox-gradient-3);
      }

      .cls-5 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#inox-gradient-1);
      }

      .cls-8 {
        fill: url(#inox-gradient-2);
      }

      .cls-9 {
        fill: url(#inox-gradient-5);
      }

      .cls-10 {
        fill: url(#inox-gradient-6);
      }

      .cls-11 {
        fill: url(#inox-gradient-7);
      }

      .cls-12 {
        fill: url(#inox-gradient-8);
      }

      .cls-13 {
        fill: url(#inox-gradient-9);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
                    x1="70.97"
                    y1="68.46"
                    x2="70.97"
                    y2="66.34"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient-1"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight + 92.52"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight + 72.67"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-0"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight + 239.72"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight + 237.6"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight + 249.65"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight + 247.51"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 70.97"
                    :y1="doorTopHeight + 98.85"
                    :x2="doorLeftWidth + 70.97"
                    :y2="doorTopHeight + 96.73"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 99.34"
                    :y1="doorTopHeight + 92.52"
                    :x2="doorLeftWidth + 99.34"
                    :y2="doorTopHeight + 72.67"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 42.65"
                    :y1="doorTopHeight + 92.52"
                    :x2="doorLeftWidth + 42.65"
                    :y2="doorTopHeight + 72.67"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 11.33"
                    :y1="doorTopHeight + 150.76"
                    :x2="doorLeftWidth + 17"
                    :y2="doorTopHeight + 150.76"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-7"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight + 86.14"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight + 79.05"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-8"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 42.65"
                    :y1="doorTopHeight + 86.14"
                    :x2="doorLeftWidth + 42.65"
                    :y2="doorTopHeight + 79.05"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-9"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 99.34"
                    :y1="doorTopHeight + 86.14"
                    :x2="doorLeftWidth + 99.34"
                    :y2="doorTopHeight + 79.05"
                    xlink:href="#inox-gradient"/>

    <g id="L12">

      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-2"
            :x="inox1X"
            :y="doorTopHeight + 66.34"
            :width="inoxW"
            height="2.12"/>
      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-3"
            :x="inox1X"
            :y="doorTopHeight1"
            :width="inoxW"
            height="2.12"/>
      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-4"
            :x="inox1X"
            :y="doorTopHeight2"
            :width="inoxW"
            height="2.13"/>
      <rect id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :x="inox1X"
            :y="doorTopHeight + 96.73"
            :width="inoxW"
            height="2.12"/>

      <path id="inox-5"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :d="`
            M ${doorLeftWidth1 + 61.07} , ${doorTopHeight + 72.67}
            V ${doorTopHeight + 92.52}
            H ${doorLeftWidth1 + 80.92}
            V ${doorTopHeight + 72.67}
            H ${doorLeftWidth1 + 61.07}
            Z
            M ${doorLeftWidth1 + 78.79} , ${doorTopHeight + 90.39}
            H ${doorLeftWidth1 + 63.2}
            V ${doorTopHeight + 74.8}
            H ${doorLeftWidth1 + 78.79}
            V ${doorTopHeight + 90.39}
            Z
            `"/>
      <path id="inox-6"
            v-if="showInox && showTricky"
            data-name="inox"
            class="cls-8"
            :d="`
            M ${doorLeftWidth2 + 89.42} , ${doorTopHeight + 72.67}
            V ${doorTopHeight + 92.52}
            h 19.84
            V ${doorTopHeight + 72.67}
            H ${doorLeftWidth2 + 89.42}
            Z
            m 17.72 , 17.72
            H ${doorLeftWidth2 + 91.54}
            V ${doorTopHeight + 74.8}
            h 15.6
            V ${doorTopHeight + 90.39}
            Z
            `"/>
      <path id="inox-7"
            v-if="showInox && showTricky"
            data-name="inox"
            class="cls-9"
            :d="`
            M ${doorLeftWidth3 + 32.73} , ${doorTopHeight + 72.67}
            V ${doorTopHeight + 92.52}
            H ${doorLeftWidth3 + 52.57}
            V ${doorTopHeight + 72.67}
            H ${doorLeftWidth3 + 32.73}
            Z
            M ${doorLeftWidth3 + 50.45} , ${doorTopHeight + 90.39}
            H ${doorLeftWidth3 + 34.86}
            V ${doorTopHeight + 74.8}
            H ${doorLeftWidth3 + 50.45}
            V ${doorTopHeight + 90.39}
            Z
            `"/>

      <polyline id="inox-8"
                v-if="showInox"
                data-name="inox"
                class="cls-11"
                :points="`
                ${doorLeftWidth1 + 74.53} ${doorTopHeight + 79.05}
                ${doorLeftWidth1 + 67.45} ${doorTopHeight + 79.05}
                ${doorLeftWidth1 + 67.45} ${doorTopHeight + 86.14}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight + 86.14}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight + 79.05}
                `"/>
      <polyline id="inox-9"
                v-if="showInox && showTricky"
                data-name="inox"
                class="cls-12"
                :points="`
                ${doorLeftWidth2 + 46.19} ${doorTopHeight + 79.05}
                ${doorLeftWidth2 + 39.11} ${doorTopHeight + 79.05}
                ${doorLeftWidth2 + 39.11} ${doorTopHeight + 86.14}
                ${doorLeftWidth2 + 46.19} ${doorTopHeight + 86.14}
                ${doorLeftWidth2 + 46.19} ${doorTopHeight + 79.05}
                `"/>
      <polyline id="inox-10"
                v-if="showInox && showTricky"
                data-name="inox"
                class="cls-13"
                :points="`
                ${doorLeftWidth3 + 102.89} ${doorTopHeight + 79.05}
                ${doorLeftWidth3 + 95.79} ${doorTopHeight + 79.05}
                ${doorLeftWidth3 + 95.79} ${doorTopHeight + 86.14}
                ${doorLeftWidth3 + 102.89} ${doorTopHeight + 86.14}
                ${doorLeftWidth3 + 102.89} ${doorTopHeight + 79.05}
                `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.26,
      inox2Left: 22,
      leaf2Left: 7,
    }
  },
  computed: {
    showTricky() {
      return this.doorWidth >= 130
    },
    inox1X() {
      return this.showBg ?
        this.doorLeftWidth + this.inox2Left :
        this.doorLeftWidth + this.leaf2Left
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset * 2 :
        this.doorWidth - this.leaf2Left * 2
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorLeftWidth2() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorLeftWidth3() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight * .8 - 5
    },
    doorTopHeight2() {
      return this.doorTopHeight + this.doorHeight * .8 + 5
    },
  },
}
</script>
